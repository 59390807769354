@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
.App {
  text-align: center;
  font-family: 'VT323', serif;
  font-weight: 400;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0a0a0a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #008b00;
}

.App-link {
  color: #004b00;
}

.fade-in-object{
  animation: fade-in 1000;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

@keyframes fade-out {
  from {
    opacity: 100;
  }
  to {
    opacity: 0;
  }
}
